<template>
  <div class="payment-activity-container">
    <vs-row class="items-start">
      <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
        <vx-card class="custom-vx-card" style="background: #f8f8f8">
          <div class="flex mb-2">
            <vs-button @click="exportCSV" class="ml-auto">Export</vs-button>
          </div>
          <vs-table :data="missedPayments" class="pay-activity-table stripes">
            <template slot="header"></template>
            <template slot="thead">
              <vs-th>
                <div class="boxBody">
                  <filter-label-component :searchParam="searchParam" :title="'Request ID'" :fieldName="'paymentRequestId'" :filtersHidden="filtersHidden" @changeFilter="changeFilter"></filter-label-component>
                  <span @click="resetInput($event, 'paymentRequestId')" v-if="searchParam.paymentRequestId" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.paymentRequestId"
                    v-bind:class="{
                      isFocus: searchParam.paymentRequestId,
                      textBlue: searchParam.paymentRequestId,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th>
                <div class="boxBody">
                  <filter-label-component :searchParam="searchParam" :title="'Product'" :fieldName="'product'" :filtersHidden="filtersHidden" @changeFilter="changeFilter"></filter-label-component>
                  <vs-select
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.product"
                    :multiple="true"
                    v-bind:class="{
                      isFocus: searchParam.product,
                      textBlue: searchParam.product,
                    }"
                  >
                    <vs-select-item v-for="(item, index) in products" :key="index" :text="item" :value="item" @click.native="checkProduct(item.productName)"/>
                  </vs-select>
                </div>
              </vs-th>
              <vs-th>
                <div class="boxBody">
                  <filter-label-component :searchParam="searchParam" :title="'Amount'" :fieldName="'amount'" :filtersHidden="filtersHidden" @changeFilter="changeFilter"></filter-label-component>
                  <span @click="resetInput($event, 'amount')" v-if="searchParam.amount" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.amount"
                    v-bind:class="{
                      isFocus: searchParam.amount,
                      textBlue: searchParam.amount,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th>
                <div class="boxBody">
                  <filter-label-component :searchParam="searchParam" :title="'Balance'" :fieldName="'balance'" :filtersHidden="filtersHidden" @changeFilter="changeFilter"></filter-label-component>
                  <span @click="resetInput($event, 'balance')" v-if="searchParam.balance" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.balance"
                    v-bind:class="{
                      isFocus: searchParam.balance,
                      textBlue: searchParam.balance,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th>
                <div class="boxBody">
                  <filter-label-component :searchParam="searchParam" :title="'Days in arrears'" :fieldName="'daysInArrears'" :filtersHidden="filtersHidden" @changeFilter="changeFilter"></filter-label-component>
                  <span @click="resetInput($event, 'daysInArrears')" v-if="searchParam.daysInArrears" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.daysInArrears"
                    v-bind:class="{
                      isFocus: searchParam.daysInArrears,
                      textBlue: searchParam.daysInArrears,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th class="filter-cross" :class="{ toggle: !filtersHidden }">
                <filter-icon size="1.5x" :fill="filterIconColor.fill" :stroke="filterIconColor.stroke" @click="toggleFilter"></filter-icon>
              </vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                <vs-td :data="tr.paymentRequest">{{ tr.paymentRequestId }}</vs-td>
                <vs-td :data="tr.paymentRequest">{{ tr.selectedPaymentPlan.productName }}</vs-td>
                <vs-td :data="tr.paymentRequest">{{ formatCurrency(tr.balances.overdueBalance) }}</vs-td>
                <vs-td :data="tr.paymentRequest">{{ tr.balances ? formatCurrency(tr.balances.balance) : 'n/a' }}</vs-td>
                <vs-td :data="tr">{{ tr.balances.overdueDays }}</vs-td>
                <vs-td :data="tr" class="text-center">
                  <a style="text-decoration: underline; cursor: pointer" @click="viewDetail(tr)">View</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-row class="vs-row justify-between my-5 items-center">
              <div class="records-per-page flex items-center">
                <label class="w-full mb-0">Records per page</label>
                <vs-select v-model="searchParam.limit" class="per-pg">
                  <vs-select-item v-for="(item, index) in limitValue" :key="index" :text="item.text" :value="item.value" />
                </vs-select>
              </div>
              <div>
                <vs-row>
                  <vPegination v-model="searchParam.pageNumber" :page-count="totalPage" :total="totalPage"></vPegination>
                </vs-row>
              </div>
            </vs-row>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import _ from "lodash";
import vPegination from "@/views/components/pagination.vue";
import filterLabelComponent from './filterLabelComponent.vue';

import { mapActions } from "vuex";
import { FilterIcon } from "vue-feather-icons";
import { sentryLog } from "@/helper/sentryLog"

export default {
  components: {
    vPegination,
    FilterIcon,
    filterLabelComponent
  },

  data() {
    return {
      filtersHidden: true,
      searchParam: {
        pageNumber: 1,
        paymentRequestId: "",
        totalData: 0,
        limit: 50,
        amount: "",
        outsandingBalance: "",
        overdueBalance: "",
        daysInArrears: "",
        daysToDefault: "",
        product: [],
        pageList: ["all"],
        sortBy: "createdAt",
        sortDir: "desc",
      },
      missedPayments: [],
      products: [],
      limit: 50,
      limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      executePlans: false
    };
  },

  methods: {
    ...mapActions("paymentRequest", ["fetchMissedPayments", "exportMissedPayments"]),

    async getMissedPayments() {
      const params = new URLSearchParams(this.searchParam).toString();
      await this.fetchMissedPayments(params).then((result)=> {
        this.missedPayments = result.data.data.docs;
        this.searchParam.totalData = result.data.data.pagination.total ? result.data.data.pagination.total : 0;
        this.executePlans = true;
      }).catch((ex) => {
        sentryLog(ex);
      });
    },

    async getProductList() {
      const productLists = this.missedPayments.map(item => {
        return item.selectedPaymentPlan.productName;
      });

      if (productLists && productLists.length) {
        const unique = (value, index, self) => {
          return self.indexOf(value) === index;
        };

        this.products = productLists.filter(unique).sort();
      }
    },

    toggleFilter() {
      this.filtersHidden = !this.filtersHidden;

      if (this.filtersHidden === true) {
        this.searchParam = {
          pageNumber: 1,
          totalData: 0,
          limit: 50,
          date: "",
          amount: "",
          balance: "",
          overdueBalance: "",
          paymentRequestId: "",
          product: "",
          pageList: ["all"],
          sortBy: "createdAt",
          sortDir: "desc",
        };
      }
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
    viewDetail(data) {
      const routeData = this.$router.resolve({
        name: "staff-payment-requests-detail",
        params: { id: data._id },
      });

      window.open(routeData.href, '_blank');
    },

    changeFilter(value) {
      this.searchParam.sortBy = value;
      this.searchParam.sortDir = this.searchParam.sortDir === "desc" ? "asc" : "desc";
    },

    onChangeInput(val) {},

    checkProduct(val) {
      // handle uncheck when all is selected
      if (this.searchParam.product.includes("All") && val !== "All") {
        this.searchParam.product.splice(this.searchParam.product.indexOf("All"), 1);
      }

      // unselect show all
      if (val == "All") {
        if (this.searchParam.product.includes("All")) {
          this.selectAllProduct();
        } else {
          this.searchParam.product = [];
        }
      }
    },

    selectAllProduct() {
      const products = this.products.map((el) => el.productName);
      this.searchParam.product = products;
    },

    resetInput(event, id) {
      this.searchParam[id] = '';
    },

    async exportCSV() {
      if (this.missedPayments.length < 1)  {
        this.showMessage('Export', 'No missed payments to export', 'danger', 'icon-times');
        return;
      }

      const params = new URLSearchParams(this.searchParam).toString();
      window.open(`${process.env.VUE_APP_API_URL}api/v1/admin/missedPayments/export?${params}`, '_blank' );
    },
  },

  mounted() {
    this.getMissedPayments();
  },

  watch: {
    "searchParam.pageNumber"(page) { this.getMissedPayments() },
    "searchParam.limit"(val) {
      this.searchParam.pageNumber = 1;
      this.getMissedPayments();
    },
    "searchParam.paymentRequestId": _.debounce(function (val) { this.getMissedPayments() }, 600),
    "searchParam.amount": _.debounce(function (val) { this.getMissedPayments() }, 600),
    "searchParam.balance": _.debounce(function (val) { this.getMissedPayments() }, 600),
    "searchParam.daysInArrears": _.debounce(function (val) { this.getMissedPayments() }, 600),
    "searchParam.daysToDefault": _.debounce(function (val) { this.getMissedPayments() }, 600),
    "searchParam.product"(val) { this.getMissedPayments() },
    "searchParam.pageList"(val) { this.getMissedPayments() },
    "searchParam.sortDir"(val) { this.getMissedPayments() },
    "searchParam.sortBy"(val) { this.getMissedPayments() },
    executePlans(value) {
      if (value) {
        this.getProductList();
      }
    }
  },

  computed: {
    filterIconColor() {
      if (!this.filtersHidden) {
        return { fill: "#0f67f4", stroke: "#0f67f4" };
      }

      return { fill: "white", stroke: "#828282" };
    },
    totalPage: function () {
      return Math.ceil(this.searchParam.totalData / this.searchParam.limit);
    },
  },
};
</script>

