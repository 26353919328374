<template>
  <div class="combo-box" @click="changeFilter(fieldName)">
    <label class="m-0 p-0 text-base font-medium">{{ title }}</label>
    <div class="icon-combo" v-if="!filtersHidden">
      <vs-icon :class="searchParam.sortBy === fieldName && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
      <vs-icon :class="searchParam.sortBy === fieldName && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    searchParam: { type: Object },
    title: { type: String },
    filtersHidden: { type: Boolean },
    fieldName: { type: String }
  },
  methods: {
    changeFilter(fieldName) {
      this.$emit('changeFilter', fieldName);
    },
  }
}
</script>
